// Import images
const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', true, /\.(gif|png|jpe?g|svg)$/))

/*---------------------------------------------------------------------------*/
// Import styles
import '../styles/application'

import 'jquery';
import $ from 'jquery';

$(function() {
    
});


// var Foo = function(){};
// Foo.globalVar = 'Tomas';

// export function tomas() {
//   alert('tomas');
// }

// window.maps = {
//   'tomas': tomas 
// }
